import { Controller } from "stimulus"
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export default class extends Controller {

    coachId = document.getElementById('coachId').value;

    button_sector_first = document.getElementById('button_sector_first');
    button_sector_second = document.getElementById('button_sector_second');
    sector_first = document.getElementById('sector_first');
    sector_second = document.getElementById('sector_second');

    initialize(){
        $('.coach-evaluation-comment').each(function(){
            //scroll height가 client height의 길이를 뛰어넘는 지점에서
            // if (elem.clientHeight < elem.scrollHeight)
            if(this.clientHeight < this.scrollHeight){
              $(this).height(this.scrollHeight)
            }
        })        
    }

    connect(){
        $(window).on('resize', function(){
            $('.coach-evaluation-comment').each(function(){
              $(this).css('height', 'auto' );
              $(this).height(this.scrollHeight)
            })
        });
    }

    // show record sector
    showFirst(event){
        if ($('#button_sector_first').hasClass('bg-black text-white')==false){
            this.button_sector_first.classList.replace('bg-white', 'bg-black');
            this.button_sector_first.classList.replace('text-black', 'text-white');
        }
        this.button_sector_second.classList.replace('bg-black', 'bg-white');
        this.button_sector_second.classList.replace('text-white', 'text-black');
    
        this.sector_first.style.display = 'block';
        this.sector_second.style.display = 'none';
        let data = { 
            "coach_id":this.player_id,
            "kind" : 'show',
            "sector": 'first',
        }
        // setLogs(data)
    }

    // show record sector
    showSecond(event){
        if ($('#button_sector_second').hasClass('bg-black text-white')==false){
            this.button_sector_second.classList.replace('bg-white', 'bg-black');
            this.button_sector_second.classList.replace('text-black', 'text-white');
        }
        this.button_sector_first.classList.replace('bg-black', 'bg-white');
        this.button_sector_first.classList.replace('text-white', 'text-black');

        this.sector_first.style.display = 'none';
        this.sector_second.style.display = 'block';
        let data = { 
            "coach_id":this.player_id,
            "kind" : 'show',
            "sector": 'second',
        }
        setLogs(data)
    }

    // print div as pdf
    // printDiv(event){
    //     div = event.target
    //     // div = $('#coach-season-evaluation')
    //     html2canvas(div, {scrollY: -window.scrollY, scale:2}).then(function(canvas){
    //       var myImage = canvas.toDataURL();
    //       this.downloadURI(myImage, "DownloadReport.png");
    //     });
    // }

    createPDF(){
        // html2canvas(document.getElementById("coach-season-evaluation"),{
        //     allowTaint: true,
        //     useCORS: true,
        //     logging: false,
        //     height: window.outerHeight + window.innerHeight,
        //     windowHeight: window.outerHeight + window.innerHeight,
        //     scale:2
        // }).then(function(canvas){
        //     var imgData = canvas.toDataURL('image/png');

        //     var imgWidth = 200;
        //     var pageHeight = imgWidth*1.414;
        //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
        //     var heightLeft = imgHeight;
        //     var doc = new jsPDF('p','mm')
        //     var position = 5;

        //     doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
        //     heightLeft-= pageHeight;

        //     // while (heightLeft >=20){
        //     //     position = heightLeft - imgHeight;
        //     //     doc.addPage();
        //     //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //     //     heightLeft -= pageHeight;
        //     // }
        //     doc.save('sample.pdf')
        // });
        
        html2canvas(document.getElementById("coach-season-evaluation")).then(function (canvas) {
			var filename = 'OTA-REPORT_' + Date.now() + '.pdf';
			var doc = new jsPDF('p', 'mm', 'a4'); 
			var imgData = canvas.toDataURL('image/png');
			var imgWidth = 210;
			var pageHeight = 295;
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;
			var position = 0;
			doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); 
			heightLeft -= pageHeight;
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			  }
			doc.save(filename); 
		});

    }
}
